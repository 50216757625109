

























































import { Vue, Component, Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Form, Divider, Button } from "element-ui";
import FormItemBox from "@/components/sign/FormItemBox.vue";
import signjs from "@/components/sign/index";
import { subProjectTeam } from "@/components/sign/index";

@Component({
    components: {
        "el-form": Form,
        "el-divider": Divider,
        "el-button": Button,
        "al-form-item": FormItemBox
    }
})
export default class ProjectBasicForm extends Vue {
    @Prop({ type: String, required: true, default: () => ({}) })
    childName!: string; //表单数据

    isshow: boolean = false; //是否显示表单
    isFirst: boolean = true;

    registItem_class_basic = "regist";

    memberNum: number = 1; //成员数量（包括项目负责人）

    //表单参数
    get registFormOpt() {
        let _opt_tem: {} = {
            block: {
                title: this.$t("sign.team_member")
            },
            opt: [
                {
                    ref: "name",
                    formkey: "name",
                    type: "input",
                    classname: `${this.registItem_class_basic}-name`,
                    label: this.$t("sign.team_name_label"),
                    placeholder: this.$t("sign.team_name_placeholder"),
                    rules: [
                        {
                            required: true,
                            message: this.$t("sign.team_name_rule_notnull"),
                            trigger: "blur"
                        }
                    ]
                },
                {
                    ref: "headimg",
                    formkey: "headimg",
                    type: "uploadimg",
                    classname: `${this.registItem_class_basic}-headimg`,
                    label: this.$t("sign.team_headimg_label"),
                    placeholder: this.$t("sign.team_headimg_placeholder")
                },
                {
                    ref: "postion",
                    formkey: "postion",
                    type: "input",
                    classname: `${this.registItem_class_basic}-postion`,
                    label: this.$t("sign.team_position_label"),
                    placeholder: this.$t("sign.team_position_placeholder"),
                    rules: [
                        {
                            required: true,
                            message: this.$t("sign.team_position_rule_notnull"),
                            trigger: "blur"
                        }
                    ]
                },
                {
                    ref: "degree",
                    formkey: "degree",
                    type: "input",
                    classname: `${this.registItem_class_basic}-degree`,
                    label: this.$t("sign.team_degree_label"),
                    placeholder: this.$t("sign.team_degree_placeholder")
                },
                {
                    ref: "graduate",
                    formkey: "graduate",
                    type: "input",
                    classname: `${this.registItem_class_basic}-graduate`,
                    label: this.$t("sign.team_graduate_label"),
                    placeholder: this.$t("sign.team_graduate_placeholder")
                },
                {
                    ref: "birthday",
                    formkey: "birthday",
                    type: "datePicker",
                    classname: `${this.registItem_class_basic}-birthday`,
                    label: this.$t("sign.team_birthday_label"),
                    placeholder: this.$t("sign.team_birthday_placeholder")
                },
                {
                    ref: "country_id",
                    formkey: "country_id",
                    type: "selector",
                    classname: `${this.registItem_class_basic}-country_id`,
                    label: this.$t("sign.team_country_id_label"),
                    placeholder: this.$t("team_country_id_placeholder"),
                    apikey: "getProjectOpt",
                    apiResKey: "country"
                },
                {
                    ref: "contact",
                    formkey: "contact",
                    type: "input",
                    classname: `${this.registItem_class_basic}-contact`,
                    label: this.$t("sign.team_contact_label"),
                    placeholder: this.$t("sign.team_contact_placeholder")
                },
                {
                    ref: "field",
                    formkey: "field",
                    type: "input",
                    classname: `${this.registItem_class_basic}-field`,
                    label: this.$t("sign.team_field_label"),
                    placeholder: this.$t("sign.team_field_placeholder")
                },
                {
                    ref: "introduction",
                    formkey: "introduction",
                    type: "textarea",
                    classname: `${this.registItem_class_basic}-introduction`,
                    label: this.$t("sign.team_intro_label"),
                    placeholder: this.$t("sign.team_intro_placeholder"),
                    rules: [
                        {
                            required: true,
                            message: this.$t("sign.team_intro_rule_notnull"),
                            trigger: "blur"
                        }
                    ]
                }
            ]
        };
        let _opt = [
            {
                block: {
                    title: this.$t("sign.team_leader")
                },
                opt: [
                    {
                        ref: "name",
                        formkey: "name",
                        type: "input",
                        classname: `${this.registItem_class_basic}-name`,
                        label: this.$t("sign.team_name_label"),
                        placeholder: this.$t("sign.team_name_placeholder"),
                        rules: [
                            {
                                required: true,
                                message: this.$t("sign.team_name_rule_notnull"),
                                trigger: "blur"
                            }
                        ]
                    },
                    {
                        ref: "headimg",
                        formkey: "headimg",
                        type: "uploadimg",
                        classname: `${this.registItem_class_basic}-headimg`,
                        label: this.$t("sign.team_headimg_label"),
                        placeholder: this.$t("sign.team_headimg_placeholder"),
                        rules: [
                            // {
                            //     required: true,
                            //     message: this.$t(
                            //         "sign.team_headimg_rule_notnull"
                            //     ),
                            //     trigger: "blur"
                            // }
                        ]
                    },
                    {
                        ref: "postion",
                        formkey: "postion",
                        type: "input",
                        classname: `${this.registItem_class_basic}-postion`,
                        label: this.$t("sign.team_position_label"),
                        placeholder: this.$t("sign.team_position_placeholder"),
                        rules: [
                            {
                                required: true,
                                message: this.$t(
                                    "sign.team_position_rule_notnull"
                                ),
                                trigger: "blur"
                            }
                        ]
                    },
                    {
                        ref: "degree",
                        formkey: "degree",
                        type: "input",
                        classname: `${this.registItem_class_basic}-degree`,
                        label: this.$t("sign.team_degree_label"),
                        placeholder: this.$t("sign.team_degree_placeholder"),
                        rules: [
                            {
                                required: true,
                                message: this.$t(
                                    "sign.team_degree_rule_notnull"
                                ),
                                trigger: "blur"
                            }
                        ]
                    },
                    {
                        ref: "graduate",
                        formkey: "graduate",
                        type: "input",
                        classname: `${this.registItem_class_basic}-graduate`,
                        label: this.$t("sign.team_graduate_label"),
                        placeholder: this.$t("sign.team_graduate_placeholder"),
                        rules: [
                            // {
                            //     required: true,
                            //     message: this.$t(
                            //         "sign.team_graduate_rule_notnull"
                            //     ),
                            //     trigger: "blur"
                            // }
                        ]
                    },
                    {
                        ref: "birthday",
                        formkey: "birthday",
                        type: "datePicker",
                        classname: `${this.registItem_class_basic}-birthday`,
                        label: this.$t("sign.team_birthday_label"),
                        placeholder: this.$t("sign.team_birthday_placeholder"),
                        rules: [
                            // {
                            //     required: true,
                            //     message: this.$t(
                            //         "sign.team_birthday_rule_notnull"
                            //     ),
                            //     trigger: "blur"
                            // }
                        ]
                    },
                    {
                        ref: "country_id",
                        formkey: "country_id",
                        type: "selector",
                        classname: `${this.registItem_class_basic}-country_id`,
                        label: this.$t("sign.team_country_id_label"),
                        placeholder: this.$t(
                            "sign.team_country_id_placeholder"
                        ),
                        apikey: "getProjectOpt",
                        apiResKey: "country",
                        rules: [
                            {
                                required: true,
                                message: this.$t(
                                    "sign.team_country_id_rule_notnull"
                                ),
                                trigger: "blur"
                            }
                        ]
                    },
                    {
                        ref: "contact",
                        formkey: "contact",
                        type: "input",
                        classname: `${this.registItem_class_basic}-contact`,
                        label: this.$t("sign.team_contact_label"),
                        placeholder: this.$t("sign.team_contact_placeholder"),
                        rules: [
                            // {
                            //     required: true,
                            //     message: this.$t(
                            //         "sign.team_contact_rule_notnull"
                            //     ),
                            //     trigger: "blur"
                            // }
                        ]
                    },
                    {
                        ref: "field",
                        formkey: "field",
                        type: "input",
                        classname: `${this.registItem_class_basic}-field`,
                        label: this.$t("sign.team_field_label"),
                        placeholder: this.$t("sign.team_field_placeholder"),
                        rules: [
                            {
                                required: true,
                                message: this.$t(
                                    "sign.team_field_rule_notnull"
                                ),
                                trigger: "blur"
                            }
                        ]
                    },
                    {
                        ref: "introduction",
                        formkey: "introduction",
                        type: "textarea",
                        classname: `${this.registItem_class_basic}-introduction`,
                        label: this.$t("sign.team_intro_label"),
                        placeholder: this.$t("sign.team_intro_placeholder"),
                        rules: [
                            {
                                required: true,
                                message: this.$t(
                                    "sign.team_intro_rule_notnull"
                                ),
                                trigger: "blur"
                            }
                        ]
                    }
                ]
            }
        ];

        if (this.memberNum > Number(_opt.length)) {
            let dif = this.memberNum - Number(_opt.length);
            for (let i = 0; i < dif; i++) {
                _opt.push(JSON.parse(JSON.stringify(_opt_tem)));
            }
        } else if (this.memberNum < Number(_opt.length)) {
            if (this.memberNum != 0) {
                let dif = Number(_opt.length) - this.memberNum;
                _opt.pop();
            }
        }
        return _opt;
    }

    //表单绑定数据模板
    registFormData_tem = {
        is_leader: "0",
        postion: "",
        name: "",
        headimg: "",
        degree: "",
        graduate: "",
        birthday: "",
        country_id: 1,
        contact: "",
        field: "",
        introduction: ""
    };

    //表单绑定数据
    registFormData: {
        value: {
            is_leader: string;
            postion: string;
            name: string;
            headimg: string;
            degree: string;
            graduate: string;
            birthday: string;
            country_id: number;
            contact: string;
            field: string;
            introduction: string;
        }[];
    } = {
        value: [
            {
                is_leader: "1",
                postion: "",
                name: "",
                headimg: "",
                degree: "",
                graduate: "",
                birthday: "",
                country_id: 1,
                contact: "",
                field: "",
                introduction: ""
            }
        ]
    };

    /* 邮箱格式验证 */
    private validateEmailRule(rule: any, value: any, callback: any) {
        let emailreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (value === "") {
            callback(new Error("邮箱账号不能为空"));
        } else if (!emailreg.test(value)) {
            callback(new Error("邮箱格式错误，请仔细核对邮箱账号"));
        } else {
            callback();
        }
    }

    /* 密码验证 */
    private validatePwdRule(rule: any, value: any, callback: any) {
        let pwdreg = /^[a-zA-Z0-9]{8,20}$/;
        if (value === "") {
            callback(new Error("密码不能为空"));
        } else if (!pwdreg.test(value)) {
            callback(new Error("密码格式错误，密码应由8-20位数字或字母组成"));
        } else {
            callback();
        }
    }

    /* 手机格式验证 */
    private validatePhoneRule(rule: any, value: any, callback: any) {
        let phonereg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (value === "") {
            callback(new Error("电话号码不能为空"));
        } else if (!phonereg.test(value)) {
            callback(new Error("电话号码格式错误"));
        } else {
            callback();
        }
    }

    /* 注册表单验证规则 */
    registFormRules = {
        name: [{ required: true, message: "联系人不能为空", trigger: "blur" }],
        mobile: [{ required: true, message: "电话不能为空", trigger: "blur" }],
        email: [
            { required: true, message: "邮箱账号不能为空", trigger: "blur" },
            { validator: this.validateEmailRule, trigger: "blur" }
        ],
        company: [
            {
                required: true,
                message: "所属机构/公司不能为空",
                trigger: "blur"
            }
        ],
        c_introduction: [
            {
                required: true,
                message: "所属机构/公司介绍不能为空",
                trigger: "change"
            }
        ],
        position: [
            {
                required: true,
                message: "职位不能为空",
                trigger: "blur"
            }
        ]
    };

    //提交表单
    sub(formName: string, callback: any, isloading?: any) {
        console.log(this.registFormData);
        signjs.validateForm(formName, this.$refs).then(res => {
            if (res) {
                isloading(); //打开loading遮罩层
                this.setFormData(this.registFormData.value).then((res: any) => {
                    subProjectTeam(
                        res,
                        this.$route.params.pid,
                        this.isFirst
                    ).then((res: any) => {
                        this.$notify({
                            title: res.status === 0 ? "成功" : "失败",
                            message: res.msg,
                            type: res.status === 0 ? "success" : "error"
                        });
                        if (callback) {
                            callback(res.status, res.data);
                        } else {
                            console.error("Regist:No Callback");
                        }
                    });
                });
            }
        });
    }

    //重置表单数据
    resetForm(formName: string) {
        //@ts-ignore
        signjs.resetForm(formName, this.$refs);
    }

    /* 提交前设置提交数据 */
    async setFormData(data: any) {
        const subdataBefore = JSON.parse(JSON.stringify(data));
        let subdata: {}[] = [];
        subdata = subdataBefore.map((x: any) => {
            return x.formData;
        });
        return subdataBefore;
    }

    // 表单单项验证
    validItem(formname: string, formItem: string | object, callback: any) {
        signjs
            .validateFormItem(formname, formItem, this.$refs)
            .then((res: any) => {
                callback(res);
            });
    }

    //添加成员
    addTeamMembers() {
        this.memberNum++;
        this.registFormData.value.push(
            JSON.parse(JSON.stringify(this.registFormData_tem))
        );
        // console.log(this.registFormData.value);
    }

    /*
     *  获取表单数据
     */
    getformData() {
        if (this.$route.params.pid) {
            this.$axios({
                url: `http://ittn_data.ittn.com.cn/api/projectteam/${this.$route.params.pid}/index`,
                method: "get",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                        "api_token"
                    )}`,
                    lang: this.$i18n.locale
                }
            }).then((res: any) => {
                this.setDataFirst(res.data.data).then(() => {
                    this.isshow = true;
                });
            });
        } else {
            this.isshow = true;
        }
    }

    async setDataFirst(data: any) {
        if (data.length >= 1) {
            this.isFirst = false;
            this.memberNum = data.length;
            this.registFormData.value = data;
        } else {
            this.isFirst = true;
        }
        // this.registFormData.value = data;
        // this.memberNum = data.length
    }

    /* 点击成员头部“+”号 */
    handleAdd(el: any, i: any) {
        this.addTeamMembers(); //调用添加空白成员方法
    }

    /* 点击成员头部“_”号 */
    handleReduce(el: any, i: any) {
        console.log(JSON.parse(JSON.stringify(this.registFormData.value)));
        this.memberNum--;
        this.registFormData.value.splice(i, 1);
        console.log(JSON.parse(JSON.stringify(this.registFormData.value)));
    }

    test(formname?: string) {}

    mounted() {
        this.getformData();
    }
}
